<template>
  <div class="product-container">
    <!--  标题  -->
    <div class="wrapper-shop-name">
      <div class="logo"></div>
      <div class="name">{{productQr.templeName}} - {{productQr.productName}}</div>
    </div>
    <!--  消费金额  -->
    <div class="wrapper-header-fix-code">
      <div class="title">消费金额</div>
      <div class="wrapper-input">
        <div class="icon-amount">￥</div>
        <div class="content-input">
          <div class="cursor-wrapper">
            <div class="cursor"></div>
          </div>
          <input
            ref="inputRef"
            v-model="amountInput"
            @input="onAmountInput"
            placeholder="请输入"
            type="number"
            class="borderless-input"
          />
        </div>
      </div>
    </div>

    <!-- 支付方式 -->
    <div class="paytype">
<!--      <div class="option" :class="{ selected: env === 'WECHATPAY' }">-->
<!--        <img :src="require('@/assets/icon-wechatpay.png')" alt="微信支付">-->
<!--        <div class="label">微信支付</div>-->
<!--        <div class="indicator"></div>-->
<!--      </div>-->
<!--      <div class="option" :class="{ selected: env === 'ALIPAY' }">-->
<!--        <img :src="require('@/assets/icon-alipay.png')" alt="支付宝支付">-->
<!--        <div class="label">支付宝支付</div>-->
<!--        <div class="indicator"></div>-->
<!--      </div>-->
      <van-radio-group v-model="radio" icon-size="16" checked-color="#da3e27">
        <van-cell-group :border="isBorder">
          <van-cell  clickable @click="radio = '1'" v-show="env === 'ALIPAY'" >
            <template #right-icon>
              <van-radio name="1" />
            </template>
            <template #title>
              <div class="option">
                <svg-icon icon-class="alipay" class-name="svgClass"/>
<!--                <img :src="require('@/assets/icon-alipay.png')" alt="支付宝支付">-->
                <label class="label">支付宝支付</label>
              </div>
            </template>
          </van-cell>
          <van-cell clickable @click="radio = '2'" v-show="env === 'WECHATPAY'" >
            <template #right-icon>
              <van-radio name="2" />
            </template>
            <template #title>
              <div class="option">
                <svg-icon icon-class="wechatpay" class-name="svgClass"/>
<!--                <img :src="require('@/assets/icon-wechatpay.png')" alt="支付宝支付">-->
                <label class="label">微信支付</label>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <!--  备注  -->
    <div class="wrapper-remark">
      <div class="item">
        <div class="label">备注</div>
        <div>
          <div class="btn-finish"></div>
        </div>
      </div>
      <div class="remark-input">
        <textarea
          v-model="remarkText"
          :maxlength="maxRemarkLength"
          placeholder="请输入订单备注，字数控制到30字以内"
        ></textarea>
        <div class="remark-length">
          <span class="real-length">{{ realRemarkLength }}</span>
          <span> / </span>
          <span>30个字</span>
        </div>
      </div>
    </div>
    <!--  支付按钮  -->
    <van-submit-bar
      :loading="ispaying"
      :disabled="payStatus"
      :button-text="btnText"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { getQueryParam } from '@/utils/url'
import DigitalKeyboard from 'digital-keyboard'
import { DLBZFBPayProductQr, getProductById } from '@/api/modules/productQr'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { handleProductQrPayment } from '@/utils/payment'


export default {
  name: 'ProductIndex',
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {
      productId: getQueryParam('productId') || '',
      payStatus: false,
      amountInput: '',
      remarkText: '',
      maxRemarkLength: 30,
      btnText: '确认支付',
      state: getQueryParam('state') || '',
      authCode: getQueryParam('auth_code') || '',
      delayTimer: null,
      radio: '0',
      isBorder: false
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('user', ['openId']),
    ...mapState('productQr',['productQr']),
    ...mapState('pay', ['ispaying']),
    ...mapState('env', ['SET_ENV_TYPE']),
    ...mapGetters('env', ['determineEnvironment']),
    realRemarkLength () {
      return this.remarkText.length
    },
    env(){
      const isWeChat = /MicroMessenger/i.test(navigator.userAgent);
      const isAlipay = /AlipayClient/i.test(navigator.userAgent);
      if(isWeChat){
        this.radio = '2'
        return "WECHATPAY"
      }else if(isAlipay){
        this.radio = '1'
        return "ALIPAY"
      }else{
        this.radio = '2'
        return "WECHATPAY"
      }
    }
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {
    ...mapMutations('productQr',['setProductQr']),
    ...mapMutations('env', ['setPayType']),
    ...mapMutations('pay', ['setPaying']),
    onAmountInput () {
      console.log(this.amountInput)
      let sanitizedInput = this.amountInput.replace(/[^\d.]/g, '');
      /* 不允许以.开头 */
      // 不允许以0开头，但允许小数点前面是0
      const dotIndex = sanitizedInput.indexOf('.');
      if (dotIndex !== -1) {
        const integerPart = sanitizedInput.slice(0, dotIndex);
        if (integerPart.length > 1 && integerPart[0] === '0') {
          sanitizedInput = integerPart.slice(1) + sanitizedInput.slice(dotIndex);
        }
      } else {
        // 处理整数部分以0开头的情况
        if (sanitizedInput.length > 1 && sanitizedInput[0] === '0') {
          sanitizedInput = sanitizedInput.slice(1);
        }
      }
      // 处理多个小数点的情况，保留两位小数
      if (dotIndex !== -1) {
        const decimalPart = sanitizedInput.slice(dotIndex + 1);
        if (decimalPart.length > 2) {
          sanitizedInput = `${sanitizedInput.slice(0, dotIndex + 3)}`;
        }
      }

      // 将金额限制在0.01到1000000之间
      const amount = parseFloat(sanitizedInput);
      if (amount > 1000000) {
        // 不在范围内时的处理
        this.$toast('单笔支付金额不能超过1000000万');
        return;
      }
      this.amountInput = sanitizedInput;
    },

    /* 确认支付 */
    async onSubmit () {
      this.setPaying(true)
      const payType = this.determineEnvironment(this.productQr.payType)
      this.setPayType(payType)
      //先判断金额是否正确
      const amountRegex = /^(?!0\.00?$)(?!0$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      if (!amountRegex.test(this.amountInput)) {
        this.$toast('金额输入错误！');
        this.setPaying(false)
        return;
        // 继续处理支付逻辑
      }
      try{
        await handleProductQrPayment(this.SET_ENV_TYPE,{
          productId: this.productQr.productId,
          templeId: this.productQr.productTempleId,
          productName: this.productQr.productName,
          openId: this.openId,
          orderPrice: this.amountInput,
          payType: this.productQr.payType,
          notes: this.remarkText
        })
      }finally {
        // 在非支付宝哆啦宝支付时执行延时操作
        if (this.SET_ENV_TYPE !== 'DLBALIPAY') {
          // 延时2秒后执行 setPaying(false)
          this.delayTimer = setTimeout(() => {
            this.setPaying(false)
          }, 2000)
        }
      }

    },
    async goDLBAlipay () {
      if (this.authCode !== '' && this.state !== '') {
        this.setPaying(true)
        await DLBZFBPayProductQr({
          state: this.state,
          auth_code: this.authCode
        }).then((res) => {
          window.ap.tradePay(
            {
              tradeNO: res.data.data.AlipayNumber,
            },
            (res) => {
              this.setPaying(false)
              if (res.resultCode === '9000') {
                this.showModal('modal1')
              } else if (res.resultCode === '6001') {
                this.$toast('用户中途取消')
              } else if (res.resultCode === '4000') {
                this.$toast('订单支付失败')
              } else if (res.resultCode === '8000') {
                this.$toast('正在处理中')
              } else if (res.resultCode === '6002') {
                this.$toast('网络连接出错')
              } else if (res.resultCode === '99') {
                this.$toast('用户忘记密码')
              }
              this.$router.push({ path: '/product' });
            }
          )
        }).catch(r => {
          alert(r)
          this.setPaying(false)
          this.$router.push({ path: '/product' });
        })
      }
    },
  },
//  生命周期 -创建完成（可以访问当前this 实例）
  async created () {
    await this.goDLBAlipay()
    //获取productId拿到product对象
    if(this.productId){
      const {data:{data} } = await getProductById(this.productId)
      this.setProductQr(data)
    }

  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
    //this._renderDigitalKeyboard();
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.product-container {
  background-color: #f5f5f5;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0.625rem 0.75rem;

  .wrapper-shop-name {
    align-items: center;
    color: rgba(0, 0, 0, .85);
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    justify-content: flex-start;
    margin-bottom: 0.625rem;
    overflow: hidden;

    .logo {
      background-image: url(https://storage.jd.com/aggregate-cashier/new/logo_shop.png);
      background-size: 100% 100%;
      flex-shrink: 0;
      height: 1.5rem;
      margin-right: 0.625rem;
      width: 1.5rem;
    }

    .name {
      flex-shrink: 1;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  .wrapper-header-fix-code {
    background-color: #fff;
    border-radius: 0.375rem;
    box-sizing: border-box;
    margin-bottom: 0.625rem;
    padding: 1rem;

    .title {
      color: rgba(0, 0, 0, .85);
      font-size: 16px;
      font-weight: 700;
    }

    .wrapper-input {
      align-items: flex-end;
      border-bottom: 0.0625rem solid rgba(0, 0, 0, .85);
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      padding: 1.375rem 0.125rem 0.5625rem;
      position: relative;

      .icon-amount {
        flex-shrink: 0;
        font-size: 1.25rem;
        transform: translateY(-0.125rem);
        margin-right: 0.3125rem; /* 添加这行样式，调整图标和输入框的间距 */
      }

      .content-input {
        align-items: flex-end;
        border: none;
        display: flex;
        flex-shrink: 1;
        height: 2.625rem;
        justify-content: flex-start;
        width: 100%;

        .cursor-wrapper {
          flex-shrink: 0;
          height: 2.375rem;
          width: 0.125rem;
        }

        .placeholder {
          color: rgba(0, 0, 0, .25);
          font-size: 1.5rem;
          font-weight: 700;
          transform: translateY(-0.125rem);
        }

        input.borderless-input {
          border: none;
          outline: none;
          width: 100%;
          font-size: 2rem;
        }
      }
    }
  }
/*支付方式
*/
  .paytype{
    background-color: #fff;
    border-radius: 0.375rem;
    box-sizing: border-box;
    padding: .5rem;
    .option{
      display: flex;
      align-items: center;
      cursor: pointer;
      .svgClass{
        font-size: 23px;
        margin-right: 5px;
      }
      //img{
      //  width: 30px; /* 图片宽度 */
      //  height: 30px; /* 图片高度 */rr
      //  margin-right: 5px; /* 图片与文字间距 */
      //}
      label{
        font-size: 14px;
      }
    }

  }

  //.paytype {
  //  background-color: #fff;
  //  border-radius: 0.375rem;
  //  box-sizing: border-box;
  //  padding: .5rem;
  //  display: flex;
  //  flex-direction: column;
  //  .option {
  //    display: flex;
  //    align-items: center;
  //    padding: 10px;
  //    cursor: pointer;
  //    .indicator {
  //      width: 10px;
  //      height: 10px;
  //      border-radius: 50%;
  //      border: 2px solid #ccc;
  //      margin-left: auto;
  //    }
  //  }
  //  .option img {
  //    width: 30px; /* 图片宽度 */
  //    height: 30px; /* 图片高度 */
  //    margin-right: 10px; /* 图片与文字间距 */
  //  }
  //  .selected .indicator {
  //    background-color: #d42322; /* 选中颜色 */
  //  }
  //}

  .wrapper-remark {
    background-color: #fff;
    border-radius: 0.375rem;
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 10px;
    .item {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding: 0.3125rem 0;

      .label {
        flex-shrink: 0;
        color: rgba(0, 0, 0, .85);
        font-size: 16px;
        font-weight: 700;
      }

      .btn-finish {
        color: #e1251b;
        font-size: .875rem;
      }
    }

    .remark-input {
      background-color: #f4f5f7;
      border-radius: 0.375rem;
      box-sizing: border-box;
      margin-top: 0.625rem;
      overflow: hidden;
      padding: 0.875rem;
      width: 100%;
      textarea {
        background-color: #f4f5f7;
        font-size: 1rem;
        min-height: 4.375rem;
        width: 100%;
        border: none;
      }
      textarea::placeholder{
        font-size: 14px;
      }
      .remark-length {
        color: #999;
        font-size: .75rem;
        text-align: right;

        .real-length {
          color: #333;
        }
      }
    }
  }

  :deep .van-submit-bar__button {
    width: 100vw;
    height: 50px;
    margin-bottom: 50px;
  }

}

</style>
